import { Snackbar, Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { ToastAlertType } from "../enums";

export default function useToast() {
  const [toast, setToast] = useState(false);
  const [toastParams, setToastParams] = useState({
    message: "Something went wrong",
    severity: ToastAlertType.success,
  });

  const showToast = ({ message, severity }) => {
    setToast(true);

    setToastParams({
      message: message,
      severity: severity || ToastAlertType.success,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setToast(false);
  };

  const Toast = (
    <Snackbar
      open={toast}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={toastParams.severity}
        sx={{ width: "100%" }}
      >
        {toastParams.message}
      </Alert>
    </Snackbar>
  );

  return [Toast, showToast];
}
