import { useRef, useState } from "react";


export function useFiles() {
    const files = useRef({ leads: null, report: null });

    const addFile = (type, file) => {
        files.current[type] = file;
    }

    const resetFiles = () => {
        files.current.leads = null;
        files.current.report = null;        
    }

    const getFiles = () => {
        return files.current;
    }

    return {files, addFile, getFiles, resetFiles}
}