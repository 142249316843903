import { Alert, Snackbar } from "@mui/material";
import { Box, styled } from "@mui/system";
import { useCallback, useEffect, useRef, useState } from "react";
import { data } from "../../data";
import { ToastAlertType } from "../../enums";
import useToast from "../../hooks/useToast";
import { DataContainer, HR, Title, DownloadCSV } from "../../styles";
import { useAppContext } from "../context";
import GrandTotal from "../grandtotal";
import { Button } from "../../styles";

export default function DataTable() {
  const { analytics, processing } = useAppContext();
  const [searchToken, setSearchToken] = useState();
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    setCsvData([]);

    // header
    setCsvData((data) => [...data, ["Name", "Report type", "Lead type", "Amount", "Invoice date"]]);
    let grandTotal = 0;
    analytics.map(data => {
      const name = data.name;
      const transactions = data.transactions;

      // name
      setCsvData((data) => [...data, [name, "", "", "", "", ""]]);
      
      let total = 0;
      // transactions
      transactions.map(transaction => {
        setCsvData((data) => [
          ...data,
          [
            "",
            transaction.type,
            transaction.leadtype,
            transaction.amount,
            transaction.invoicedate,
          ],
        ]);
        total += +transaction.amount;
        grandTotal += +transaction.amount;
      });
      setCsvData((data) => [...data, ["", "", "", total, ""]]);
    });
    setCsvData((data) => [...data, ["Total", "", "", grandTotal, ""]]);
  }, [analytics]);
  
  const transactions = (row, i) => {
    return (
      <tr key={i}>
        <td></td>
        <td>{row.type}</td>
        <td>{row.leadtype}</td>
        <td>${row.amount}</td>
        <td>{row.invoicedate}</td>
      </tr>
    );
  };

  const transactionsTotal = (transactions) => {
    const total = transactions.reduce((p, c) => p + +c.amount, 0);

    return (
      <tr className="total">
        <td></td>
        <td></td>
        <td></td>
        <td>${total.toFixed(2)}</td>
        <td></td>
      </tr>
    );
  };

  const grandTotal = () => {
    const total = analytics
      .map((a) => a.transactions)
      .map((t) => t.map((a) => a.amount))
      .map((a) => a.reduce((p, c) => p + +c, 0))
      .reduce((p, c) => p + c, 0);

    return total.toFixed(2);
  };

  return (
    <>
      <DataContainer>
        <Title>Data Report</Title>
        <HR />
        {processing && (
          <span className="processing">Processing, please wait...</span>
        )}
        {analytics.length > 0 && (
          <div className="analytics">
            <Box
              display={"flex"}
              justifyContent="flex-end"
              sx={{ width: "100%" }}
            >
              <DownloadCSV data={csvData} filename={"analytics_report.csv"}>
                Download
              </DownloadCSV>
            </Box>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Report type</th>
                  <th>Lead type</th>
                  <th>Amount</th>
                  <th>Invoice date</th>
                </tr>
              </thead>
              <tbody>
                {analytics.map((a, i) => (
                  <>
                    <tr key={a.name} className="first-row">
                      <td className="name">{a.name}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {a.transactions.map((t, i) => transactions(t, i))}
                    {transactionsTotal(a.transactions)}
                  </>
                ))}
                {/* <tr className="grand-total">
                  <td className="name">Grand Total</td>
                  <td></td>
                  <td></td>
                  <td>${grandTotal()}</td>
                  <td></td>
                </tr> */}
              </tbody>
            </table>
            <GrandTotal amount={grandTotal()} />
          </div>
        )}
      </DataContainer>
    </>
  );
}
