import { Button, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useRef, useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useAppContext } from "./context";
import request from "../httpService";
import { ToastAlertType } from "../enums";
import useToast from "../hooks/useToast";

const processFiles = (data) => {
  return request({
    method: "POST",
    url: "process",
    data,
  });
};

export function Begin() {
  // const leadsFileuploadRef = useRef();
  // const reportFileuploadRef = useRef();
  const [ToastComponent, showToast] = useToast();
  const [uploadStatus, setUploadStatus] = useState({
    leadsUploaded: false,
    salesUploaded: false,
  });
  const {
    callListener,
    registerListener,
    removeEventListener,
    addFile,
    getFiles,
    setAnalytics,
    toolbarDataChanged,
    setToolbarData,
    stats,
    setStats,
    setProcessing,
    getFilter,
    leadsFileuploadRef,
    reportFileuploadRef
  } = useAppContext();

  useEffect(() => {
    
    registerListener("leads", () => leadsFileuploadRef.current.click());

    registerListener("reports", () => {
      reportFileuploadRef.current.click();
    });

    registerListener("process", () => handleProcessClick());
    return () => {
      if (removeEventListener) {
        ["leads", "reports", "process"].forEach((event) =>
          removeEventListener(event)
        );
      }
    };
  }, [leadsFileuploadRef, reportFileuploadRef]);

  const handleProcessClick = () => {
    const toolbarFilter = getFilter();
    const files = getFiles();
    if (!files.leads || !files.report) {
      showToast({
        message: "Please select files",
        severity: ToastAlertType.error,
      });
      return;
    }

    // no error checks at this point
    setAnalytics([]);

    let formData = new FormData();

    setProcessing(true);

    for (var i = 0; i < files.leads.files.length; i++) {
      formData.append("leads", files.leads.files[i]);
    }

    for (var i = 0; i < files.report.files.length; i++)
      formData.append("reports", files.report.files[i]);

    // add filter props
    if (toolbarFilter.month && toolbarFilter.year) {
      formData.append("month", toolbarFilter.month);
      formData.append("year", toolbarFilter.year);
    }

    processFiles(formData)
      .then(async (response) => {
        setProcessing(false);
        if (response.data) {
          const keys = Object.keys(response.data);

          //console.log(response);

          setToolbarData({ ...response });

          setStats({
            ...response,
            newclients: response.convertedcount,
            totalLeads: response.notconvertedcount + response.convertedcount,
            marketingSource: response.marketingsource,
            topCustomerSpending: response.spendingbycustomer,
          });

          if (keys.length > 0) {
            keys.forEach((name) => {
              const row = {
                name: name,
                transactions: response.data[name],
              };

              //console.log(row);
              setAnalytics((a) => [...a, row]);
            });
          } else {
            showToast({
              message: "No records found",
              severity: ToastAlertType.info,
            });
          }
        }
      })
      .catch((err) => {
        setProcessing(false);
        showToast({
          message: "Error while processing data. Please contact Administrator.",
          severity: ToastAlertType.error,
        });
        console.log(err);
      });
  };

  const handleLeadsFileupload = (event) => {
    const leads = {
      files: event.target.files,
    };

    addFile("leads", leads);
    setUploadStatus({ ...uploadStatus, leadsUploaded: true });
  };

  const handleSalesFileUpload = (event) => {
    const report = {
      files: event.target.files,
    };

    addFile("report", report);
    setUploadStatus({ ...uploadStatus, salesUploaded: true });
  };
  return (
    <Box display={"flex"} flexDirection="column" alignItems={"center"} sx={{ height: '100%', mt: 2 }}>

      <Typography color={"white"} variant="h4">Step 1: Let's Begin</Typography>
      <Divider flexItem sx={{
        borderColor: '#fff',
        margin: '0px 160px',
        mt: 2
      }} />
      {ToastComponent}
      <Button variant="contained" onClick={() => callListener("leads")} sx={{ background: '#fff', color: '#000', mt: 2, width: '250px' }}>
        Upload Lead Report
        {uploadStatus.leadsUploaded && <CheckCircleIcon color="success" />}
      </Button>
      <Button variant="contained" onClick={() => callListener("reports")} sx={{ background: '#fff', color: '#000', mt: 1, width: '250px' }}>
        Upload Sales Report
        {uploadStatus.salesUploaded && <CheckCircleIcon color="success" />}
      </Button>
      <Button variant="contained"

        onClick={() => callListener("process")}
        sx={{
          background: "#00adb5",
          color: "#fff",
          mt: 1,
          width: '250px'
        }}

      >
        Process
      </Button>

      <input
        name="leadsfile"
        id="leadsfile"
        accept="text/csv"
        multiple
        type="file"
        ref={leadsFileuploadRef}
        onChange={handleLeadsFileupload}
      />
      <input
        name="reportfile"
        id="reportfile"
        accept="text/csv"
        multiple
        type="file"
        ref={reportFileuploadRef}
        onChange={handleSalesFileUpload}
      />
    </Box>

  )
}