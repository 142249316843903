import { useRef, useState } from "react";


export function useToolbar() {
  const filter = useRef({ month: '', year: '' });

  const addFilter = (type, value) => {
    filter.current[type] = value;
  };

  const resetFilter = () => {
    filter.current.month = '';
    filter.current.year = '';
  };

  const getFilter = () => {
    return filter.current;
  };

  return { filter, addFilter, getFilter, resetFilter };
}