import styled from 'styled-components';
import { CSVLink } from "react-csv";

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 14px;
`;

export const DataContainer = styled.div`
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
`;

export const TabsContainer = styled.div`
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
`;

export const SideBarContainer = styled.div`
    display: flex;
    justify-content: flex-start;    
    flex-direction: column;
    padding: 8px;
    background: #232831;
    border-right: 1px solid #555;
    width: 200px;
`;

export const GrandTotalContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  left: 0;
  font-weight: bold;
  font-size: 1.25em;  
  background-color: #3a4858;
  width: 100%;
  padding: 10px;
  box-shadow: 1px 1px 14px 1px #00adb5;
`;

export const Button = styled.button`  
  border-radius: 3px;
  padding: 6px 24px;
  border-radius: 3px;
  border: none;
  margin: 4px 10px;
  cursor: pointer;
  color: ${props => props.fontColor || '#000'};
  background: ${props => props.color || '#fff'};
`;

export const Title = styled.h3`
  width: 100%;
  text-align: center;
`;

export const HR = styled.hr`  
    width: 90%;
    height: 2px;
    color: red;
    border: none;
    border-top: 1px solid #aaa;    
`;


export const DownloadCSV = styled(CSVLink)(() => ({
  fontFamily: "'Roboto', 'Helvetica', 'Arial'",
  fontSize: "0.875rem",
  lineHeight: 1.25,
  letterSpacing: "0.02em",
  color: "#fff",
  background: "#00adb5",
  padding: "12px 16px",
  borderRadius: "2px",
  textDecoration: "none",
  marginBottom: 8,
  ":hover": {
    color: "gray",
  },
}));