import "./App.css";
import { AppProvider } from "./components/context";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/theme";
import DataAnalyticsApp from "./DataAnalyticsApp";

function App() {

  return (
    <AppProvider>
      <ThemeProvider theme={theme}>
        <DataAnalyticsApp />
      </ThemeProvider>
    </AppProvider>
  );
}

export default App;
