import { Box, Divider, Typography } from "@mui/material";

export default function AppCard({ title, content }) {
  return (
    <Box
      display="flex"
      flexDirection={"column"}      
      alignItems="center"
      sx={{ p: 1 }}
    >
      <Box
        display={"flex"}
        flexDirection="column"
        alignItems="flex-start"
        sx={{
          borderRadius: 1,
          p: 4,
          minWidth: 600,
          minHeight: 150,
          background: "#323640",
        }}
      >
        <Typography variant="subtitle" color="#00adb5c0" sx={{ mb: 0, fontSize: '1.125rem' }}>
          {title}
        </Typography>
        <Divider flexItem color="#fff" sx={{mt: 1, mb: 1}} />
        {content}
      </Box>
    </Box>
  );
}
