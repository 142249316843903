import {
  createContext,
  useContext,
  useState,
  useRef,
  useCallback,
} from "react";
import { useFiles } from "../../hooks/useFiles";
import { useToolbar } from "../../hooks/useToolbar";

export const AppContext = createContext();
export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const listeners = useRef(new Map());

  const leadsFileuploadRef = useRef();
  const reportFileuploadRef = useRef();

  const [toolbarDataChanged, setToolbarDataChanged] = useState({
    month: "",
    year: "",
  });
  const [toolbarData, setToolbarData] = useState({
    monthlist: [],
    yearlist: [],
  });
  const [analytics, setAnalytics] = useState([]);
  const { addFile, getFiles } = useFiles();
  const { addFilter, getFilter, resetFilter } = useToolbar();

  const [stats, setStats] = useState({
    totalsales: 0,
    avgcustomerspending: 0,
    newclients: 0,
    totalLeads: 0,
    quarters: {
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0,
    },
    marketingSource: {},
    topCustomerSpending: {},
  });
  const [processing, setProcessing] = useState(false);
  
  const registerListener = useCallback((tag, listener) => {
    if (!listeners.current.has(tag)) {
      listeners.current.set(tag, listener);
    }
  }, []);
  const removeListener = useCallback((tag) => {
    if (listeners.current.has(tag)) {
      listeners.current.delete(tag);
    }
  }, []);
  const callListener = useCallback((tag, data = null) => {
    if (listeners.current.has(tag)) {
      listeners.current.get(tag)(data);
    } else {
      console.error(`${tag} not found.`);
    }
  }, []);

  const value = {
    toolbarData,
    setToolbarData,
    toolbarDataChanged,
    setToolbarDataChanged,    
      addFile,
    getFiles,
    addFilter,
    getFilter,
    resetFilter,
    registerListener,
    removeListener,
    callListener,
    setAnalytics,
    analytics,
    stats,
    setStats,
    processing,
    setProcessing,
    leadsFileuploadRef,
    reportFileuploadRef
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
