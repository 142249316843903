import axios from "axios";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_END_POINT,
});

function request(options) {
  const onSuccess = function (response) {
    return response.data;
  };

  const onFailure = function (error) {
    //console.error("Request failed", error);

    if (error.response) {
      // Request was made but server responded with something other than 2xx
      if (process.env.REACT_APP_DEVELOPMENT) {
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
        console.error("Headers:", error.response.headers);
      }
    } else {
      // Something else happened while setting up the request triggered the error
      if (process.env.REACT_APP_DEVELOPMENT) {
        console.error("Error Message:", error.message);
      }
    }

    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onFailure);
}

export default request;
