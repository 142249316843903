import {
  Typography,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  TableCell,
  Grid,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { useState, useRef, useEffect } from "react";
import { DataContainer, HR, Title, DownloadCSV } from "../../styles";
import Toolbar from "../toolbar";
import AppCard from "../ui/AppCard";
import useToast from "../../hooks/useToast";
import { FixedSizeList as List } from "react-window";
import { useAppContext } from "../context";

const AppTableCell = styled(TableCell)(() => ({
  color: "#fff",
}));

export default function Dashboard() {
  const [csvData, setCsvData] = useState([]);
  const [ToastComponent, showToast] = useToast();
  const {
    analytics,
    stats,
    processing,
    callListener,
  } = useAppContext();

  const [topCustomerSpending, setTopCustomerSpending] = useState([]);

  useEffect(() => {
    setCsvData([]);

    // header
    setCsvData((data) => [...data, ["", "", "", "", ""]]);

    setCsvData((data) => [
      ...data,
      ["Total Sales (ROI)", "$" + stats.totalsales],
    ]);
    setCsvData((data) => [...data, ["", "", "", ""]]);
    setCsvData((data) => [
      ...data,
      ["Avg. Customer Spending", "$" + stats.avgcustomerspending],
    ]);

    setCsvData((data) => [...data, ["", "", "", ""]]);

    // customers converted
    setCsvData((data) => [...data, ["Customers Converted", "", ""]]);
    setCsvData((data) => [...data, ["", "New Clients", "Total Leads", ""]]);
    setCsvData((data) => [
      ...data,
      [
        "",
        stats.newclients,
        stats.totalLeads,
        ((stats.newclients / stats.totalLeads) * 100).toFixed(2) + "%",
      ],
    ]);

    setCsvData((data) => [...data, ["", "", "", ""]]);

    // quaterly stats
    setCsvData((data) => [...data, ["Quaterly Stats", "", ""]]);
    setCsvData((data) => [...data, ["", "Q1", "Q2", "Q3", "Q4"]]);
    setCsvData((data) => [
      ...data,
      [
        "",
        "$" + stats.quarters.q1.toFixed(2),
        "$" + stats.quarters.q2.toFixed(2),
        "$" + stats.quarters.q3.toFixed(2),
        "$" + stats.quarters.q4.toFixed(2),
      ],
    ]);

    setCsvData((data) => [...data, ["", "", "", ""]]);
    // marketing source
    setCsvData((data) => [...data, ["Marketing Source", "", ""]]);
    setCsvData((data) => [...data, ["", "Source", "Total"]]);
    Object.keys(stats.marketingSource).map((source) => {
      setCsvData((data) => [
        ...data,
        ["", source.toLocaleUpperCase(), stats.marketingSource[source]],
      ]);
    });

    setCsvData((data) => [...data, ["", "", "", ""]]);

    // top customers
    setCsvData((data) => [...data, ["Top Customers", "", ""]]);
    setCsvData((data) => [...data, ["", "Name", "Amount"]]);
    const list = [];
    Object.keys(stats.topCustomerSpending).forEach((customer) => {
      const row = {
        name: customer,
        amount: stats.topCustomerSpending[customer],
      };
      list.push(row);
    });
    // sort the list
    list.sort((a, b) => b.amount - a.amount);

    list.forEach(item => {
      setCsvData((data) => [
        ...data,
        ["", item.name.toLocaleUpperCase(), '$' + item.amount],
      ]);
    });

  }, [stats]);

  useEffect(() => {
    const list = [];
    Object.keys(stats.topCustomerSpending).forEach((customer) => {
      const row = {
        name: customer,
        amount: stats.topCustomerSpending[customer],
      };
      list.push(row);
    });
    // sort the list
    list.sort((a, b) => b.amount - a.amount);
    setTopCustomerSpending(list);
  }, [stats.topCustomerSpending]);

  const customersConvertedContent = (
    <Box display={"flex"}>
      <Box
        display={"flex"}
        flexDirection="column"
        alignItems={"center"}
        sx={{ mr: 2 }}
      >
        <Typography variant="caption">New Clients</Typography>
        <Typography variant="h3">{stats.newclients}</Typography>
      </Box>
      <Typography variant="h2" sx={{ mt: 1 }}>
        /
      </Typography>
      <Box
        display={"flex"}
        flexDirection="column"
        alignItems={"center"}
        sx={{ ml: 2 }}
      >
        <Typography variant="caption">Total Leads</Typography>
        <Typography variant="h3">{stats.totalLeads}</Typography>
      </Box>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ borderColor: "#fff", ml: 2 }}
      />
      <Typography variant="h3" sx={{ mt: 2, ml: 4 }}>
        {((stats.newclients / stats.totalLeads) * 100).toFixed(2)}%
      </Typography>
    </Box>
  );

  const marketingSourceContent = (
    <TableContainer sx={{ maxHeight: 150, maxWidth: 600 }}>
      <Table size="small">
        <TableHead>
          <TableRow sx={{ background: "#00adb5" }}>
            <AppTableCell>Marketing Source</AppTableCell>
            <AppTableCell>Total</AppTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(stats.marketingSource).map((source) => (
            <TableRow key={source}>
              <AppTableCell>{source.toLocaleUpperCase()}</AppTableCell>
              <AppTableCell>{stats.marketingSource[source]}</AppTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const Row = ({ index, style }) => (
    <Box
      style={style}
      display="flex"
      justifyContent={"space-between"}
      alignItems="center"
      borderBottom="1px solid rgba(244,244,244,1)"
    >
      <Typography variant="subtitle2" sx={{ paddingLeft: 1 }}>
        {topCustomerSpending[index].name.toLocaleUpperCase()}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{ alignItems: "flex-end", paddingRight: 1 }}
      >
        ${topCustomerSpending[index].amount.toFixed(2)}
      </Typography>
    </Box>
  );
  const topCustomersBySpendingContent = (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ maxHeight: 150, maxWidth: 600, overflowX: "hidden" }}
    >
      <Box
        display="flex"
        justifyContent={"space-between"}
        sx={{ background: "#00adb5", p: 1 }}
      >
        <Typography>Name</Typography>
        <Typography sx={{ alignItems: "flex-end", paddingRight: 1 }}>
          Amount
        </Typography>
      </Box>
      <List
        style={{ overflowX: "hidden" }}
        height={150}
        width={600}
        itemSize={30}
        itemCount={topCustomerSpending.length}
        itemData={topCustomerSpending}
      >
        {Row}
      </List>
    </Box>
  );

  const customerSpendingContent = (
    <Typography variant="h2">${stats.avgcustomerspending}</Typography>
  );

  const quaterlyStats = (
    <Box width="100%" height="100%" sx={{ position: "relative" }}>
      <Box
        display={"flex"}
        alignItems="stretch"
        width="100%"
        justifyContent={"space-between"}
      >
        <Box display={"flex"} flexDirection="column" alignItems={"center"}>
          <Typography
            variant="caption"
            sx={{ color: "lightgray", fontSize: "1.12rem" }}
          >
            Q1
          </Typography>
          <Typography variant="h5">${stats.quarters.q1.toFixed(2)}</Typography>
        </Box>
        <Box display={"flex"} flexDirection="column" alignItems={"center"}>
          <Typography
            variant="caption"
            sx={{ color: "lightgray", fontSize: "1.12rem" }}
          >
            Q2
          </Typography>
          <Typography variant="h5">${stats.quarters.q2.toFixed(2)}</Typography>
        </Box>
      </Box>

      <Divider flexItem sx={{ borderColor: "#fff" }} />
      <Divider
        flexItem
        sx={{
          borderColor: "#fff",
          position: "absolute",
          minHeight: "100%",
          left: "50%",
          top: 0,
        }}
        orientation="vertical"
      />
      <Box
        display={"flex"}
        alignItems="stretch"
        width="100%"
        justifyContent={"space-between"}
      >
        <Box display={"flex"} flexDirection="column" alignItems={"center"}>
          <Typography
            variant="caption"
            sx={{ color: "lightgray", fontSize: "1.12rem" }}
          >
            Q3
          </Typography>
          <Typography variant="h5">${stats.quarters.q3.toFixed(2)}</Typography>
        </Box>
        <Box display={"flex"} flexDirection="column" alignItems={"center"}>
          <Typography
            variant="caption"
            sx={{ color: "lightgray", fontSize: "1.12rem" }}
          >
            Q4
          </Typography>
          <Typography variant="h5">${stats.quarters.q4.toFixed(2)}</Typography>
        </Box>
      </Box>
    </Box>
  );

  const handleSearch = () => {
    callListener("process");
  };

  return (
    <DataContainer>
      <Title>Data Insights</Title>
      {/* <HR />
      <Toolbar onSearch={handleSearch} /> */}
      <HR />
      {processing && (
        <span className="processing">Processing, please wait...</span>
      )}
      {analytics.length > 0 && (
        <>
          <Box
            display={"flex"}
            justifyContent="flex-end"
            sx={{ width: "100%", mr: 16 }}
          >
            <DownloadCSV data={csvData} filename={"analytics_insights.csv"}>
              Download
            </DownloadCSV>
          </Box>
          <Grid
            container
            spacing={{ xs: 2, md: 1 }}
            columns={{ xs: 12, sm: 6, md: 4 }}
            justifyContent="center"
          >
            <Grid item>
              <AppCard
                title={"Total Sales (ROI)"}
                content={
                  <Typography variant="h2">${stats.totalsales}</Typography>
                }
              />
            </Grid>
            <Grid item>
              <AppCard
                title={"Customers Converted"}
                content={customersConvertedContent}
              />
            </Grid>
            <Grid item>
              <AppCard
                title={"Avg. Customer Spending"}
                content={customerSpendingContent}
              />
            </Grid>

            <Grid item>
              <AppCard title={"Quaterly Stats"} content={quaterlyStats} />
            </Grid>
            <Grid item>
              <AppCard
                title={"Marketing Source"}
                content={marketingSourceContent}
              />
            </Grid>

            <Grid item>
              <AppCard
                title={"Top Customers"}
                content={topCustomersBySpendingContent}
              />
            </Grid>
          </Grid>
        </>
      )}

      {ToastComponent}
    </DataContainer>
  );
}
