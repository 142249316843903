import "./App.css";
import { MainContainer} from "./styles";
import DataTable from "./components/datatable";
import { useEffect, useRef, useState } from "react";
import Dashboard from "./components/dashboard";
import { Box, Button, Divider } from "@mui/material";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Begin } from "./components/Begin";
import { useAppContext } from "./components/context";
import Loading from "./components/Loading";

const steps = [
  "Let's Begin", 'Report', 'Data Insights',
];

const stepMapper = ['Report', 'Data Insights']

function DataAnalyticsApp() {
  const [value, setValue] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState([]);
  const [canGoToNext, setCanGoToNext] = useState(true);
  const { analytics, processing } = useAppContext();

  useEffect(() => {
    setCanGoToNext(false);

    if( analytics.length > 0 && !processing ) {
        setCanGoToNext(true);
    }
  }, [activeStep, analytics, processing]);


  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleNext = () => {
    let newCompleted = completed;

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setCompleted(newCompleted);
  };
  return (
        <MainContainer>
         <Loading loading={processing} />
         <p style={{fontSize: '0.5rem'}}>ver: 0.1</p>
          <Stepper activeStep={activeStep} sx={{ mt: 2, color: '#fff' }}>
            {steps.map((step, index) => {
              const stepProps = {};
              return (
                <Step key={step} {...stepProps}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          
          {analytics.length > 0 && <Box display={"flex"} flexDirection="row" sx={{ mt: 2 }}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{
                color: '#fff'
              }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            {activeStep === steps.length - 1
              ?
              <Button onClick={() => setActiveStep(0)} disabled={!canGoToNext}>Restart</Button>
              : <Button onClick={handleNext} disabled={!canGoToNext}>{stepMapper[activeStep]}</Button>
            }
          </Box>}
          <Box flexGrow={1} display="flex" flexDirection={"column"} justifyContent="center" >
            {
              {

                0:
                  <Begin />,
                1:
                <DataTable />,
                2:
                <Dashboard />,
              }[activeStep]
            }
          </Box>
          <Divider />

        </MainContainer>
  );
}

export default DataAnalyticsApp;
