import styled from "@emotion/styled"
import { CircularProgress, colors, Typography } from "@mui/material";
import { Box } from "@mui/system"


const LoadingContainer = styled(Box)(({show}) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: '#000',
    opacity: 0.7,
    display: show ? 'flex' : 'none',
    zIndex: 999,
    top: 0,
    left: 0,
    color: '#fff',
}));


export default function Loading({loading, message}) {
    return (
        <LoadingContainer alignItems="center" justifyContent="center" show={loading}>
            <Typography variant="h4">{message || "Please wait..."}</Typography>
            <CircularProgress sx={{ml: 2}}/>
        </LoadingContainer>
    )
}