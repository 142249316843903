import { createTheme, darken, lighten } from "@mui/material/styles";

const Colors = {
    primary: "#00adb5",
    secondary: "#95defb",
    success: "#4CAF50",
    info: "#00a2ff",
    danger: "#FF5722",
    warning: "#FFC107",
    dark: "#0e1b20",
    light: "#aaa",
    muted: "#abafb3",
    border: "#DDDFE1",
    inverse: "#2F3D4A",
    shaft: "#333",
    ///////////////
    // Grays
    ///////////////
    dim_grey: "#696969",
    dove_gray: "#d5d5d5",
    body_bg: "#f3f6f9",
    ///////////////
    // Solid Color
    ///////////////
    white: "#fff",
    black: "#000",
    dimensions: {
        drawerWidth: 240,
        borderRadius: 4,
    },
    margins: {
        margin: 12,
    },
};

const theme = createTheme({
    palette: {
        primary: {
            main: Colors.primary,
        },
        secondary: {
            main: Colors.secondary,
        },
        success: {
            main: Colors.success
        },
        warning: {
            main: Colors.warning
        },
        info: {
            main: Colors.info
        },
        danger: {
            main: Colors.danger
        }
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },

        },
        MuiButton: {
            styleOverrides: {
                root: {
                "&.Mui-disabled": {
                    color: Colors.dim_grey
                },}
            }
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    "&.Mui-active": {
                        color: Colors.primary
                    },
                    '&.Mui-disabled': {
                        color: Colors.dim_grey
                    },
                    '&.Mui-completed': {
                        color: Colors.primary
                    }
                },
            }
        },
        MuiStepIcon: {
            styleOverrides: {
                "root": {
                        color: Colors.dim_grey
                },
                "&.Mui-completed": {
                    color: Colors.primary
                }
            }
        }
    },
});

export default theme;
